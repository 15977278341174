<template>
  <b10-base>
    <v-radio-group
      v-model="form.tipo_cierre"
    >
      <v-radio
        v-if="hasPermFinalizarParteYOt"
        :label="TIPO_CIERRE_SUBIR_PARTE.descripciones[TIPO_CIERRE_SUBIR_PARTE.tipos.finalizarOT]"
        :value="TIPO_CIERRE_SUBIR_PARTE.tipos.finalizarOT"
      />
      <v-radio
        :label="TIPO_CIERRE_SUBIR_PARTE.descripciones[TIPO_CIERRE_SUBIR_PARTE.tipos.reasignarParte]"
        :value="TIPO_CIERRE_SUBIR_PARTE.tipos.reasignarParte"
      />
      <v-radio
        :label="TIPO_CIERRE_SUBIR_PARTE.descripciones[TIPO_CIERRE_SUBIR_PARTE.tipos.mantenerOTPendiente]"
        :value="TIPO_CIERRE_SUBIR_PARTE.tipos.mantenerOTPendiente"
      />
    </v-radio-group>
    <v-checkbox
      v-if="tieneLineasPendientesInstalar && form.tipo_cierre !== TIPO_CIERRE_SUBIR_PARTE.tipos.mantenerOTPendiente"
      v-model="form.albaranar_material_pendiente_instalacion"
      label="Albaranar material pendiente de instalación"
    />
    <v-subheader
      v-if="enviarDoc"
    >
      Documentación a enviar
    </v-subheader>
    <v-checkbox
      v-if="enviarDocFichaSistema"
      v-model="form.doc_ficha_sistema"
      label="Ficha de sistema CRA"
    />
    <v-text-field
      v-if="enviarDocFichaSistema"
      v-model="form.doc_ficha_sistema_email"
      clearable
      flat
      label="Destinatarios"
      :rules="formRules.doc_ficha_sistema_email"
      :disabled="!form.doc_ficha_sistema"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ParteSubirFormData'
import { PARAMETRO, TIPO_CIERRE_SUBIR_PARTE } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    hasPermFinalizarParteYOt: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        tipo_cierre: null,
        albaranar_material_pendiente_instalacion: true,
        doc_ficha_sistema: false,
        doc_ficha_sistema_email: '',
      },
      formRules: {
        tipo_cierre: [
          v => !!v || 'Campo requerido'
        ],
        doc_ficha_sistema_email: [
          v => !this.form.doc_ficha_sistema || this.form.doc_ficha_sistema && !!v || 'Campo requerido'
        ],
      },
      TIPO_CIERRE_SUBIR_PARTE,
      tieneLineasPendientesInstalar: false,
      sistema: {},
    }
  },
  computed: {
    enviarDocFichaSistema () {
      return this.sistema.idcentral_alarma && this.sistema.central_alarma_email
    },
    enviarDoc () {
      return this.enviarDocFichaSistema
    },
  },
  async created () {
    const parametrosNecesarios = [ PARAMETRO.parametros.ALBARANAR_MATERIAL_PENDIENTE_INSTALACION, ]
    const resp = await Data.selectLookups(this, parametrosNecesarios)
    const albaranarMaterialPendienteInstalacion = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.ALBARANAR_MATERIAL_PENDIENTE_INSTALACION })
    if (albaranarMaterialPendienteInstalacion) {
      this.$set(this.form, 'albaranar_material_pendiente_instalacion', (albaranarMaterialPendienteInstalacion.valor) === '1')
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      const [datasetLineasPendientes]= await Data.selectOTTieneLineasPendientesInstalar(
        this, formData.orden_trabajo.idorden_trabajo
      )
      this.tieneLineasPendientesInstalar = datasetLineasPendientes.tiene_lineas_pdte_instalar
      // si no tiene permiso para finalizar parte y OT
      // o si hay mas de un parte pendiente
      const [datasetOtrosPartesPendientes] = await Data.selectOtrosPartesPendientes(
        this, formData.orden_trabajo.idorden_trabajo, id,
      )
      if (!this.hasPermFinalizarParteYOt || datasetOtrosPartesPendientes.length > 0) {
        this.$set(this.form, 'tipo_cierre', TIPO_CIERRE_SUBIR_PARTE.tipos.mantenerOTPendiente)
      } else {
        this.$set(this.form, 'tipo_cierre', TIPO_CIERRE_SUBIR_PARTE.tipos.finalizarOT)
      }
      this.sistema = await Data.selectSistema(this, formData.orden_trabajo.idsistema)
      if (this.sistema.idcentral_alarma && this.sistema.central_alarma_email) {
        this.$set(this.form, 'doc_ficha_sistema', true)
        this.$set(this.form, 'doc_ficha_sistema_email', this.sistema.central_alarma_email)
      }
    },
  },
}
</script>
