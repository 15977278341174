<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <parte-subir-form
          :id="routeParams.idparte_trabajo"
          :has-perm-finalizar-parte-y-ot="hasViewPerm(permissions.partes.finalizarParteYOt)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ParteSubirForm from './components/ParteSubirForm'
import Data from './ParteSubirData'
import { doSync } from '@/sync/upload'
import { get } from 'vuex-pathify'
import { TIPO_CIERRE_SUBIR_PARTE } from '@/utils/consts'

export default {
  components: { ParteSubirForm },
  mixins: [formPageMixin],
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = `Subir parte Nº${this.routeParams.idparte_trabajo}`
    },
    async submitForm () {
      this.$loading.showManual('Subiendo parte')
      try {
        const parteTrabajo = await Data.selectParteTrabajo(this, this.routeParams.idparte_trabajo)
        const respSubir = await Data.subir(
          this, this.routeParams.idparte_trabajo, this.formData, this.usuarioIdtecnico, parteTrabajo.idsistema
        )
        if (this.formData.tipo_cierre === TIPO_CIERRE_SUBIR_PARTE.tipos.reasignarParte) {
          this.$alert.showSnackbarSuccess(
            `Se ha creado el parte de trabajo Nº${respSubir.idparte_trabajo}`
          )
        }
      } finally {
        this.$loading.hide()
      }
      await doSync(this.$offline, this.$api, this.$loading, this.$alert)
      // redirección al index porque el parte ya no existe localmente (y puede venir de ParteResumenClienteView -> 404)
      this.$appRouter.replace({ name: 'index' })
    },
  },
}
</script>
