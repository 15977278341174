import { APIFilter } from '@/utils/api'
import { uploadParteTrabajo } from '@/sync/upload'
import { TIPO_CIERRE_SUBIR_PARTE } from '@/utils/consts'

export default {
  async selectParteTrabajo (Vue, idparteTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idparte_trabajo', idparteTrabajo)
    return (
      await Vue.$api.call('parteTrabajo.select', { filter: apiFilter })
    ).data.result.dataset[0]
  },
  async subir (Vue, id, formData, idtecnico, idsistema) {
    const parteTrabajo = await Vue.$offline.parteTrabajo.row(id)
    let toReturn = null
    if (formData.tipo_cierre === TIPO_CIERRE_SUBIR_PARTE.tipos.finalizarOT) {
      toReturn = await Vue.$online.ordenTrabajo.finalizar(
        parteTrabajo.parte_trabajo.idorden_trabajo,
        formData.albaranar_material_pendiente_instalacion
      )
    } else if (formData.tipo_cierre === TIPO_CIERRE_SUBIR_PARTE.tipos.reasignarParte) {
      toReturn = await Vue.$online.ordenTrabajo.asignar(parteTrabajo.parte_trabajo.idorden_trabajo, idtecnico)
    }
    await uploadParteTrabajo(Vue, id)
    // enviar documentacion
    if (formData.doc_ficha_sistema) {
      await Vue.$api.call('informe.generarFichaSistema', { idsistema, destinatario: formData.doc_ficha_sistema_email })
    }
    return toReturn
  },
}
