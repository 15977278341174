import { APIFilter } from '@/utils/api'
import { PARTE_TRABAJO } from '@/utils/consts'

export default {
  async selectLookups (Vue, idsParametros) {
    let apiCalls = []
    // Parámetros
    const apiFilterParamertos = new APIFilter()
    apiFilterParamertos.addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParamertos } })
    return await Vue.$api.batchCall(apiCalls)
  },
  // ToDo cambiar estas consultas a un selectFormLookups
  async selectOTTieneLineasPendientesInstalar (Vue, idordenTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idorden_trabajo', idordenTrabajo)
    const resp = await Vue.$api.call('ordenTrabajo.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectOtrosPartesPendientes (Vue, idordenTrabajo, idparteTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idorden_trabajo', idordenTrabajo)
      .addExact('estado', PARTE_TRABAJO.estados.pendiente)
      .addNE('idparte_trabajo', idparteTrabajo)
    const resp = await Vue.$api.call('parteTrabajo.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectSistema (Vue, idsistema) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idsistema', idsistema)
    const resp = await Vue.$api.call('sistema.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectFormData (Vue, id) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .where(tables.parte_trabajo.idparte_trabajo.eq(id))
      .exec()
    )[0]
  },
}
